// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css");

//
// extra events and initializers
//
document.addEventListener("turbolinks:load", () => {
  // datepicker (flatpickr)
  flatpickr("[data-behavior='flatpickr']", {
    dateFormat: "Y-m-d"
  });

  // init jQuery/Bootstrap tooltips
  $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip();

  // mobile menu, switch from hamburger to X and back
  $('.navbar-toggle').on('click', function () {
    $(this).toggleClass('open');
  });

  // hamburger menu
  $('#menu_toggle').on('click', function () {
    $('#topnav-menu-content').toggleClass('show');
  });

  // "signed in as" section
  $('#user_well').on('click', function () {
    $('#user_well_dropdown').toggleClass('show');
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
